.video-js .vjs-picture-in-picture-control {
  cursor: pointer;
  @include flex(none);
  display: none;

  & .vjs-icon-placeholder {
    @extend .vjs-icon-picture-in-picture-enter;
  }
}
// Switch to the exit icon when the player is in Picture-in-Picture
.video-js.vjs-picture-in-picture .vjs-picture-in-picture-control .vjs-icon-placeholder {
  @extend .vjs-icon-picture-in-picture-exit;
}
