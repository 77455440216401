.video-js .vjs-big-play-button {
  font-size: 3em;
  line-height: $big-play-button--line-height;
  height: $big-play-button--height;
  width: $big-play-button--width; // Firefox bug: For some reason without width the icon wouldn't show up. Switched to using width and removed padding.
  display: none;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 0;
  cursor: pointer;
  opacity: 1;
  border: $big-play-button--border-size solid $primary-foreground-color;

  // Need a slightly gray bg so it can be seen on black backgrounds
  @include background-color-with-alpha($primary-background-color, $primary-background-transparency);
  @include border-radius(0.3em);
  @include transition(all 0.4s);

  // Since the big play button doesn't inherit from vjs-control, we need to specify a bit more than
  // other buttons for the icon.
  & .vjs-icon-placeholder:before {
    @extend .vjs-icon-play;

    @extend %icon-default;
  }
}

// Display Big Play Button in Safari only
// _::-webkit-full-page-media, _:future, :root .video-js .vjs-big-play-button {
//   display:block;
// }
@media not all and (min-resolution:.001dpcm) { 
  .video-js .vjs-big-play-button {
    font-size: 3em;
    line-height: $big-play-button--line-height;
    height: $big-play-button--height;
    width: $big-play-button--width; // Firefox bug: For some reason without width the icon wouldn't show up. Switched to using width and removed padding.
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 0;
    cursor: pointer;
    opacity: 1;
    border: $big-play-button--border-size solid $primary-foreground-color;
  
    // Need a slightly gray bg so it can be seen on black backgrounds
    @include background-color-with-alpha($primary-background-color, $primary-background-transparency);
    @include border-radius(0.3em);
    @include transition(all 0.4s);
  
    // Since the big play button doesn't inherit from vjs-control, we need to specify a bit more than
    // other buttons for the icon.
    & .vjs-icon-placeholder:before {
      @extend .vjs-icon-play;
  
      @extend %icon-default;
    }
  }
  
}

// Allow people that hate their poster image to center the big play button.
.vjs-big-play-centered .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-top: -($big-play-button--height / 2);
  margin-left: -($big-play-button--width / 2);
}

.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
  border-color: $primary-foreground-color;

  @include background-color-with-alpha($secondary-background-color, $secondary-background-transparency);
  @include transition(all 0s);
}

// Hide if controls are disabled, the video is playing, or native controls are used.
.vjs-controls-disabled .vjs-big-play-button,
.vjs-has-started .vjs-big-play-button,
.vjs-using-native-controls .vjs-big-play-button,
.vjs-error .vjs-big-play-button {
  display: none;
}

// Show big play button if video is paused and .vjs-show-big-play-button-on-pause is set on video element
.vjs-has-started.vjs-paused.vjs-show-big-play-button-on-pause .vjs-big-play-button {
  display: block;
}
