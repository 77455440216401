.video-js button {
  background: none;
  border: none;
  color: inherit;
  display: inline-block;

  font-size: inherit; // IE in general. WTF.
  line-height: inherit;
  text-transform: none;
  text-decoration: none;
  transition: none;

  -webkit-appearance: none;
  -moz-appearance:    none;
  appearance:         none;
}

.vjs-control .vjs-button {
  width: 100%;
  height: 100%;
}
