.vjs-poster {
  // display: inline-block;
  // vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  // background-color: #000000;
  background-size: 100% auto;
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // border: 1px solid black;
}

// Hide the poster after the video has started playing
.vjs-has-started .vjs-poster {
  display: none;
}

// Don't hide the poster if we're playing audio
.vjs-audio.vjs-has-started .vjs-poster {
  display: block;
}

// Hide the poster when native controls are used otherwise it covers them
.vjs-using-native-controls .vjs-poster {
  display: none;
}
