.video-js .vjs-play-control {
  cursor: pointer;
}
.video-js .vjs-play-control .vjs-icon-placeholder {
  @include flex(none);
  @extend .vjs-icon-play;
}
.video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder {
  @extend .vjs-icon-pause;
}
.video-js .vjs-play-control.vjs-ended .vjs-icon-placeholder {
  @extend .vjs-icon-replay;
}
