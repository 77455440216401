.video-js .vjs-mute-control {
  cursor: pointer;
  @include flex(none);

  & .vjs-icon-placeholder {
    @extend .vjs-icon-volume-high;
  }
}

.video-js .vjs-mute-control.vjs-vol-0 .vjs-icon-placeholder {
  @extend .vjs-icon-volume-mute;
}
.video-js .vjs-mute-control.vjs-vol-1 .vjs-icon-placeholder {
  @extend .vjs-icon-volume-low;
}
.video-js .vjs-mute-control.vjs-vol-2 .vjs-icon-placeholder {
  @extend .vjs-icon-volume-mid;
}

.video-js .vjs-volume-control {
  cursor: pointer;
  margin-right: 1em;
  @include display-flex;
}
.video-js .vjs-volume-control.vjs-volume-horizontal {
  width: 5em;
}

.video-js .vjs-volume-panel .vjs-volume-control {
  visibility: visible;
  opacity: 0;
  width: 1px;
  height: 1px;
  margin-left: -1px;
}

.video-js .vjs-volume-panel {
  &.vjs-hover .vjs-volume-control,
  &:active .vjs-volume-control,
  &:focus  .vjs-volume-control,
  & .vjs-volume-control:active,
  &.vjs-hover .vjs-mute-control  ~ .vjs-volume-control,
  & .vjs-volume-control.vjs-slider-active {
    &.vjs-volume-horizontal {
      width: 5em;
      height: 3em;
      margin-right: 0;
    }
    visibility: visible;
    opacity: 1;
    position: relative;

    &.vjs-volume-vertical {
      left: -3.5em;
      @include transition(left 0s);
    }
    $transition-property: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
    @include transition($transition-property);
  }

  &.vjs-volume-panel-horizontal {
    &.vjs-hover,
    &:active,
    &.vjs-slider-active {
      width: 10em;

      @include transition(width 0.1s);
    }
    &.vjs-mute-toggle-only {
      width: 4em;
    }
  }

  @include transition(width 1s);
}

.video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical {
  height: 8em;
  width: 3em;
  left: -3000em;

  $transition-property: visibility 1s, opacity 1s, height 1s 1s, width 1s 1s, left 1s 1s, top 1s 1s;
  @include transition($transition-property)
}

.video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-horizontal {
  $transition-property: visibility 1s, opacity 1s, height 1s 1s, width 1s, left 1s 1s, top 1s 1s;
  @include transition($transition-property)
}

.video-js.vjs-no-flex .vjs-volume-panel .vjs-volume-control.vjs-volume-horizontal {
  width: 5em;
  height: 3em;

  visibility: visible;
  opacity: 1;
  position: relative;

  @include transition(none);
}

.video-js.vjs-no-flex .vjs-volume-control.vjs-volume-vertical,
.video-js.vjs-no-flex .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical {
  position: absolute;
  bottom: 3em;
  left: 0.5em;
}

.video-js .vjs-volume-panel {
  @include display-flex;
}

.video-js .vjs-volume-bar {
  margin: 1.35em 0.45em;
}

.vjs-volume-bar.vjs-slider-horizontal {
  width: 5em;
  height: 0.3em;
}

.vjs-volume-bar.vjs-slider-vertical {
  width: 0.3em;
  height: 5em;
  margin: 1.35em auto;
}

.video-js .vjs-volume-level {
  position: absolute;
  bottom: 0;
  left: 0;

  background-color: $primary-foreground-color;

  @extend .vjs-icon-circle;

  // Volume handle
  &:before {
    position: absolute;
    font-size: 0.9em; // Doing this to match the handle on play progress.
  }
}

.vjs-slider-vertical .vjs-volume-level {
  width: 0.3em;

  // Volume handle
  &:before {
    top: -0.5em;
    left: -0.3em;
  }
}
.vjs-slider-horizontal .vjs-volume-level {
  height: 0.3em;

  // Volume handle
  &:before {
    top: -0.3em;
    right: -0.5em;
  }
}

.video-js .vjs-volume-panel.vjs-volume-panel-vertical {
  width: 4em;
}

// Assumes volume starts at 1.0.
.vjs-volume-bar.vjs-slider-vertical .vjs-volume-level {
  height: 100%;
}

.vjs-volume-bar.vjs-slider-horizontal .vjs-volume-level {
  width: 100%;
}

.video-js .vjs-volume-vertical {
  width: 3em;
  height: 8em;
  bottom: 8em;

  @include background-color-with-alpha($primary-background-color, $primary-background-transparency);
}

.video-js .vjs-volume-horizontal .vjs-menu {
  left: -2em;
}
